<template>
  <div class="chat-item"
       @click="showChat"
       v-show="info.team_level >= selfTeamLevel || info.is_online > 0"
       :class="info.ucount || info.is_online ? '' : 'off-line'">
    <div class="modal"></div>
    <div class="avatar">
      <img :src="info.loge_image_path || info.head_icon"
           alt=""
           @click.stop="mapCenterToUser" />
    </div>
    <div class="center">
      <div class="top">
        <div class="name">{{ info.pm_name || info.name }}</div>
        <!-- <div class="type">
            <span class="img">
              <img :src="icons.drone" alt="">
            </span>
            <span>{{info.ucount || (type === 'normal' ? '' : 0)}}</span>
        </div> -->
      </div>
      <div>{{ DRONEMODEL }}</div>
      <div :style="{ color: this.statusColor }">
        {{ getStatus(info) }}
      </div>
    </div>
    <div class="right">
      <div class="imgIcom">
        <!-- <img :src="icons.rapid"
             v-if="rapidImg"
             class="rapid"
             @click.stop="rapidFn()"
             alt="" /> -->
        <!--<img :src="icons.rapid"  class="rapid" @click.stop="rapidFn()" alt="">-->
        <div v-show="info.NewMsg != 0"
             class="red-msg">{{ info.NewMsg }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import UnreadMsg from '@/assets/img/statistics/message.png';
import Msg from '@/assets/img/statistics/message.png';
import rapid from '@/assets/img/statistics/rapid.png';
import Drone from '@/assets/img/statistics/drone_black.png';
export default {
  name: 'chat-item',
  props: {
    info: {
      type: Object,
      default: (e) => {},
    },
    type: {
      type: String,
      default: () => 'normal',
    },
  },
  watch: {
    // info
    info: {
      deep: true,
      handler: function (val) {
        // console.log("chat-item的监听", val);
        if (
          val.DroneLongitude &&
          val.DroneLatitude &&
          val.DroneLongitude !== 'NaN' &&
          val.DroneLatitude !== 'NaN' &&
          val.DroneLongitude !== '0' &&
          val.DroneLatitude !== '0'
        ) {
          this.rapidImg = true;
        }
        this.getUavNameStatus(val);
        this.getStatus(val);
      },
    },
  },
  mounted() {
    this.selfTeamLevel = JSON.parse(
      sessionStorage.getItem('user_info')
    ).team_level;
  },

  data() {
    return {
      icons: {
        msg: Msg,
        unreadMsg: UnreadMsg,
        drone: Drone,
        rapid,
      },
      rapidImg: true,
      DRONEMODEL: '',
      statusColor: '#999',
      selfTeamLevel: 1,
    };
  },
  methods: {
    // 抛出定位事件 回到用户的经纬度
    mapCenterToUser() {
      this.$emit('mapCenterToUser');
    },
    // 抛出显示聊天框事件
    showChat() {
      //   this.info.hadNewMsg = 0;
      this.info.NewMsg = 0;
      this.$_bus.$emit('showChatHandler', this.info);
    },
    getStatus(info) {
      let status = info.IsPublish;
      // console.log("getStatus", info.name, status);
      let val = '';
      if (info.is_online == 0) {
        val = '离线';
        this.statusColor = '#999';
      } else {
        switch (status) {
          case 0:
            val = '在线';
            this.statusColor = '#61D76B';
            break;
          case 1:
            val = '正在直播';
            this.statusColor = '#FF0000';
            break;
          default:
            val = '';
            this.statusColor = '#999';
        }
      }

      return val;
    },
    getUavNameStatus(info) {
      // console.log("getUavNameStatus", info);
      let val = '';
      if (info.DRONEMODEL !== '' && info.track_tag == 1) {
        val = info.DRONEMODEL;
      }
      return val;
    },
    rapidFn() {
      this.$_bus.$emit('showRapidHandler', this.info);
    },
  },
};
</script>

<style lang="scss" scoped>
.off-line {
  position: relative;
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(255, 255, 255, 0.5);
  }
}
.chat-item {
  cursor: pointer;
  margin: 0 auto;
  padding: 11px 14px;
  width: 321px;
  height: 62px;
  background-color: #5d5d5d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #777777;
  // margin-bottom: pcRem(10);
  // &:last-child {
  //   margin-bottom: 0;
  // }
  .avatar {
    flex: 0 0 40;
    width: 40px;
    height: 40px;
    border: 2px solid rgba(255, 104, 91, 1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      flex: 0 0 40px;
      height: 40px;
      object-fit: cover;
    }
  }
  .center {
    flex: 1;
    // padding: 0 pcRem(20);
    margin-left: 22px;
    .super {
      .name {
        font-size: 16px;
        color: #333;
      }
    }
    .top {
      display: flex;
      align-items: center;
      font-size: 12px;
      .name {
        font-size: 16px;
      }
      // .type {
      //   display: flex;
      //   align-items: center;
      //   // margin-left: pcRem(20);
      //   .img {
      //     width: pcRem(16);
      //     height: pcRem(16);
      //     margin-right: pcRem(10);
      //     font-size: 0;
      //     img {
      //       width: pcRem(16);
      //       height: pcRem(16);
      //     }
      //   }
      //   span {
      //     padding-top: -1px;
      //   }
      // }
    }
  }
  .right {
    position: relative;
    .imgIcom {
      display: flex;
      align-items: center;
      img {
        display: inline-block;
        width: 34px;
        height: 34px;
      }
      .rapid {
        width: 20px;
        height: auto;
        margin-right: 10px;
      }
      .red-msg {
        width: 22px;
        height: 22px;
        background: rgba(255, 70, 70, 1);
        border-radius: 50%;
        text-align: center;
        color: #fff;
      }
    }
  }
}
</style>
