<template>
  <div class="watch-list">
    <div class="title">
      {{ dataConfig.title }}
      <!-- <img class="icon"
           :src="pilot"
           alt=""
           @click="PilotPlayModal = !PilotPlayModal" /> -->
    </div>
    <div class="lists">
      <template>
        <div v-if="dataConfig.data && dataConfig.data.length">
          <div class="TeamChatbottom" @click="TeamChat">
            <div>
              <img :src="group_chat" />
              <h1>团队聊天</h1>
            </div>
          </div>
          <ChatItem
            v-for="(chat, index) in dataConfig.data"
            :key="index"
            :type="dataConfig.type"
            :info="Object.assign({}, chat, chat)"
            @mapCenterToUser="mapCenterToUser(chat)"
            @click.native="clickHandle(chat, index)"
          />
        </div>

        <!--<Collapse v-if="dataConfig.data && dataConfig.data.length">-->
        <!--<Panel-->
        <!--:name="index + ''"-->
        <!--v-for="(item, index) in dataConfig.data"-->
        <!--:key="index"-->
        <!--&gt;-->
        <!--<span class="header">-->
        <!--<span class="status" :style="getStyleObj(item)"></span>-->
        <!--<span class="h-title">{{ item.team_name }}</span>-->
        <!--<span class="count">{{ item.onLineCount }}/{{ item.ucount }}</span>-->
        <!--</span>-->
        <!--<div slot="content">-->
        <!--<chat-item-->
        <!--v-for="(chat, index) in item.teamuser"-->
        <!--:key="index"-->
        <!--:type="dataConfig.type"-->
        <!--:info="chat"-->
        <!--@mapCenterToUser="mapCenterToUser(chat)"-->
        <!--@click.native="clickHandle(chat)"-->
        <!--/>-->
        <!--</div>-->
        <!--</Panel>-->
        <!--</Collapse>-->
        <div class="no-data" v-else>暂无数据</div>
      </template>
    </div>
    <ChatModal
      ref="chatModal"
      @cancel="cancelHandle"
      :showModal="showChat"
      :info="liveInfo"
      :operations="operations"
      @sendSocketMsg="sendSocketMsg"
    />

    <!--Pilot直播流播放-->
    <!--弹窗区-->
    <Modal
      footer-hide
      width="1100"
      v-model="PilotPlayModal"
      class-name="PilotPlay-modal"
      @on-visible-change="init"
    >
      <div slot="header" class="header">
        <span>Pilot直播流播放</span>
        <i class="ivu-icon ivu-icon-md-repeat" @click="init"></i>
      </div>
      <div class="content">
        <div class="list">
          <Spin fix v-if="spinShow" size="large"></Spin>
          <div
            class="item"
            :class="{ select: change == ind }"
            v-for="(item, ind) in PilotPlayList"
            :key="ind"
            @click="PilotPlay(item, ind)"
          >
            <div class="head_portrait">
              <img :src="item.head_icon" alt="" />
            </div>
            <span>{{ item.name }}</span>
            <i class="ivu-icon ivu-icon-ios-arrow-dropright"></i>
          </div>
          <p v-if="PilotPlayList.length === 0">暂无数据</p>
        </div>
        <div class="videoBox">
          <!-- <div 
               id="video"
               style="background-color: #333333; width: 100%; height: 100%"></div> -->
          <video
            id="video"
            autoplay
            style="width= 100%; height=100%; object-fit: cover;background-color: #333333;"
            @click.capture.stop=""
          ></video>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import ChatModal from './chat-modal.vue'
import ChatItem from './chat-item.vue'
import pilot from '@/assets/img/Icon/Pilot.png'
import aaa from '@/assets/img/aaa.png'
import not_live from '@/assets/img/statistics/not_live.png'
import group_chat from '@/assets/img/Icon/group_chat.png'
import { GetGroupMsgRecord } from '@/utils/javaApi.js'
import { getPrivateChatRecord } from '@/utils/javaApi.js'
export default {
  name: 'watch-list',
  components: {
    ChatItem,
    ChatModal,
  },
  props: {
    dataConfig: {
      type: Object,
      default: () => {},
    },
    TeamChatData: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.user_info = JSON.parse(sessionStorage.getItem('user_info'))
    console.log('this.user_info===============', this.user_info)
    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      this.$emit('fetchData', value)
      this.$emit('changeTeamData', value)
      console.log('value', value, this.user_info.team_id == value)
      //   if (this.user_info.team_id == value) {
      //     this.TeamChatShou = true;
      //   } else {
      //     this.TeamChatShou = false;
      //   }
    })
    this.$_bus.$off('showChatOnMap').$on('showChatOnMap', (data, from) => {
      this.showChatHandler(data, from)
    })
  },
  data() {
    return {
      pilot,
      aaa,
      not_live,
      group_chat,
      showChat: false,
      liveInfo: {},
      operations: [
        'dash-board',
        'live',
        'chat',
        'send-voice',
        'remote-control',
        'speak-control',
      ],
      PilotPlayModal: false,
      PilotPlayList: [],
      player: null,
      change: null,
      spinShow: false,
      peopleList: [],
      chatId: 0,
      TeamChatShou: false,
      user_info: {},
    }
  },
  watch: {
    'dataConfig.data': {
      deep: true,
      handler: function (val) {
        val.forEach((element) => {
          if (element.user_id == this.chatId) {
            this.liveInfo = Object.assign({}, element, element)
          }
        })
      },
    },
    showChat(val) {
      if (val) {
        this.dataConfig.data.map((el) => {
          if (el.user_id == this.chatId && val == true) {
            // el.hadNewMsg = 0;
            el.NewMsg = 0
          }
        })
      }
    },
    // liveInfo: {
    //   deep: true,
    //   handler: function (val) {
    //     console.log("watch-list的监听liveInfo", this.dataConfig); // 确定正常监听,只在发生动作时变化
    //   },
    // },
  },
  methods: {
    // 抛出要发送的消息
    sendSocketMsg(data) {
      this.$emit('sendSocketMsg', data)
    },
    // 抛出定位事件的数据
    mapCenterToUser(item) {
      if (!item.is_online) return
      this.$emit('mapCenterToUser', item)
    },
    // 处理展示聊天框事件
    showChatHandler(item, from) {
      console.log('展示聊天框事件--群聊记录', item)
      console.log('展示聊天框事件--群聊记录', window.location.host)
      console.log('from', from)
      let findUser = this.dataConfig.data.find((ele) => {
        return item.user_id == ele.user_id
      })
      console.log('findUser', findUser)
      if (findUser) {
        let { IsPublish, DRONEMODEL, is_online } = findUser
        if (is_online !== 1) {
          return
        }
        let is_link_uav =
          DRONEMODEL == '未知' || DRONEMODEL == '' || !DRONEMODEL ? false : true
        let is_publish = IsPublish == 0 || IsPublish == '0' ? false : true
        if (this.dataConfig.type === 'normal') {
          if (item.isTeam) {
            this.operations = ['chat', 'send-voice', 'send-msg']
          } else if (from == 'user' || !from) {
            // this.operations = [
            //   'dash-board',
            //   'live',
            //   'chat',
            //   'send-voice',
            //   'remote-control',
            //   'speak-control',
            //   'send-msg'
            // ]
            //没连接飞机并且未开启直播
            if (!is_link_uav && !is_publish) {
              this.$Message.error('该用户未连接设备,未开启直播')
              return
              //连接飞机并且未开启直播
            } else if (is_link_uav && !is_publish) {
              this.operations = [
                'chat',
                'dash-board',
                'remote-control',
                'speak-control',
              ]
              this.$refs.chatModal.setModalFromWatch('remoteControl')
              this.$refs.chatModal.hideChatModel = false
              //没连接飞机并且开启直播
            } else if (!is_link_uav && is_publish) {
              this.operations = ['chat', 'live']
              this.$refs.chatModal.hideChatModel = true
              //连接飞机并且开启直播
            } else if (is_link_uav && is_publish) {
              this.operations = [
                'live',
                'chat',
                'dash-board',
                'remote-control',
                'speak-control',
              ]
              //控制弹窗状态进入远程控制
              this.$refs.chatModal.setModalFromWatch('remoteControl')
              this.$refs.chatModal.hideChatModel = false
            }
          } else if (from == 'plane') {
            if (is_publish == 0) {
              return
            }
            this.operations = [
              'live',
              'dash-board',
              'remote-control',
              'speak-control',
            ]
            this.$refs.chatModal.hideChatModel = true
          }
          // item.hadNewMsg = 0;
          item.NewMsg = 0
          this.liveInfo = item
          this.showChat = true
          this.chatId = item.user_id
          this.$refs.chatModal.showSwitchChat = false
        }
      } else {
        if (from == 'team') {
          this.operations = ['chat', 'send-voice', 'send-msg']
          item.NewMsg = 0
          this.liveInfo = item
          this.showChat = true
          this.chatId = item.user_id
          this.$refs.chatModal.showSwitchChat = false
        }
      }
    },
    // 超管点击进入一级管理员看到的团队列表
    clickHandle(item) {
      item.msgList = []
      let list = item.msgList
      //获取聊天历史记录
      let userId = JSON.parse(sessionStorage.getItem('user_info')).user_id
      this.$post(getPrivateChatRecord(), {
        user_id: userId,
        to_user_id: item.user_id,
      })
        .then((res) => {
          if (res.data) {
            //历史记录数据过滤
            for (let i = 0, len = res.data.length; i < len; i++) {
              if (res.data[i] !== '') {
                let isSelf = res.data[i].user_id == userId
                if (res.data[i].type == 'user_msg') {
                  list.push({
                    avatar: isSelf
                      ? this.user_info.head_icon
                      : res.data[i].head_icon,
                    role: isSelf ? 'self' : 'remote',
                    msg_type: 'normal',
                    content: res.data[i].content,
                    isPlayingVoice: false,
                    voiceTimeLength: res.data[i].time_leng,
                    //   name: isSelf ? this.user_info.name : res.data[i].name,
                  })
                } else if (res.data[i].type == 'voice') {
                  list.push({
                    avatar: isSelf
                      ? this.user_info.head_icon
                      : res.data[i].head_icon,
                    role: isSelf ? 'self' : 'remote',
                    msg_type: 'voice',
                    content: res.data[i].content,
                    isPlayingVoice: false,
                    voiceTimeLength: res.data[i].time_leng,
                    voiceId: i - 1,
                    //   name: isSelf ? this.user_info.name : res.data[i].name,
                  })
                }
                /////////
              }
            }
            console.log('聊天记录', item.msgList)
            this.showChatHandler(item)
          }
        })
        .catch((err) => {
          console.log(err)
        })
      //   if (this.dataConfig.type === 'super') {
      //     this.$router.push({
      //       path: '/live/watch-lists',
      //       query: {
      //         id: item.pm_id,
      //       },
      //     });
      //   }
    },
    // 隐藏弹窗
    cancelHandle() {
      this.showChat = false
    },
    getStyleObj(item) {
      // let color = ''
      // switch(item.testType) {
      //   case 'red':
      //     color = '#FF685B';
      //     break;
      //   case 'orange':
      //     color = '#FF9500';
      //     break;
      //   case 'green':
      //     color = '#61D16B';
      //     break;
      //   case 'blue':
      //     color = '#4C98FF';
      //     break;
      //   case 'purple':
      //     color = '#C179E5';
      //     break;
      //   default:
      //     color = 'red';
      // }
      return {
        backgroundColor: item.comment || 'transparent',
      }
    },
    // 加载列表
    init() {
      this.spinShow = true
      if (this.PilotPlayModal) {
        this.$post('/base/common/getPointPush', {
          client: 'web',
        }).then((res) => {
          this.spinShow = false
          if (res.code == 1) {
            if (res.data) {
              this.PilotPlayList = res.data
            } else {
              if (this.player !== null) {
                this.player.videoClear()
                this.player = null
              }
            }
          } else {
            this.$Message.error(res.msg_customer)
          }
        })
      } else {
        this.player.videoClear()
        this.player = null
        this.change = null
      }
    },
    // 播放直播
    PilotPlay(item, ind) {
      this.change = ind
      //   if (this.player !== null) {
      //     this.player.destroy();
      //   }
      //   this.player = new TcPlayer('video', {
      //     // "m3u8": item.play_url.m3u8_url,
      //     flv: item.play_url.flv_url,
      //     h5_flv: true,
      //     // rtmp: item.play_url.default_url,
      //     live: true,
      //     // "controls":'none',
      //     autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
      //     width: '100%', //视频的显示宽度
      //     height: '100%', //视频的显示高度
      //   });

      // ========
      ///////
      /**
       * flv.js
       * 离线的flv播放器
       * 不依赖flash
       * 目前作为通用flv播放器,取代tcplayer
       */
      if (this.player !== null) {
        this.player.pause()
        this.player.unload()
        this.player.detachMediaElement()
        this.player.destroy()
        this.player = null
      }
      this.$nextTick(() => {
        if (flvjs.isSupported()) {
          this.player = flvjs.createPlayer({
            type: 'flv',
            url: res.data.flv_url,
            isLive: true,
          })
          this.player.attachMediaElement(document.getElementById('video'))
          this.player.load()
          this.player.play()
        } else {
          this.$message.error('不支持flv格式')
          return
        }
      })
      // ========

      //   if (this.player !== null) {
      //     this.player.videoClear();
      //     this.player = null;
      //   }
      //   let videoObject = {
      //     container: "#video", //容器的ID或className
      //     variable: "player", //播放函数名称
      //     autoplay: true,
      //     live: true,
      //     video: res.data.default_url,
      //     loaded: "loadedHandler", //当播放器加载后执行的函数
      //   };
      //   this.player = new ckplayer(videoObject);
    },
    //团队监控列表
    changeTeamData(value, selectedData) {
      console.log('团队切换时-value', value)
      console.log('团队切换时-selectedData', selectedData)
      this.$emit('fetchData', value[value.length - 1])

      this.$emit(
        'changeTeamData',
        value[value.length - 1],
        selectedData[selectedData.length - 1]
      )
      if (this.user_info.team_id == value[value.length - 1]) {
        this.TeamChatShou = true
      } else {
        this.TeamChatShou = false
      }
    },
    //打开团队聊天
    TeamChat() {
      console.log('团队聊天id', this.TeamChatData)
      //获取团队聊天历史记录
      let teamId = this.user_info.team_id
      let userId = this.user_info.user_id
      this.$post(GetGroupMsgRecord(), {
        team_id: teamId,
      })
        .then((res) => {
          if (res.data) {
            this.TeamChatData.msgList = []
            //历史记录数据过滤
            let msgList = this.TeamChatData.msgList
            for (let i = 0, len = res.data.length; i < len; i++) {
              if (res.data[i] !== '') {
                let isSelf = res.data[i].user_id == userId
                if (res.data[i].msg_type == 'normal') {
                  msgList.push({
                    avatar: isSelf
                      ? this.user_info.head_icon
                      : res.data[i].head_icon,
                    role: isSelf ? 'self' : 'remote',
                    msg_type: 'normal',
                    content: res.data[i].msg_content,
                    isPlayingVoice: false,
                    voiceTimeLength: res.data[i].msg_attribute,
                    name: isSelf ? this.user_info.name : res.data[i].name,
                  })
                } else if (res.data[i].msg_type == 'voice') {
                  msgList.push({
                    avatar: isSelf
                      ? this.user_info.head_icon
                      : res.data[i].head_icon,
                    role: isSelf ? 'self' : 'remote',
                    msg_type: 'voice',
                    content: res.data[i].msg_content,
                    isPlayingVoice: false,
                    voiceTimeLength: res.data[i].msg_attribute,
                    voiceId: i - 1,
                    name: isSelf ? this.user_info.name : res.data[i].name,
                  })
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
      this.showChatHandler(this.TeamChatData, 'team')
    },
  },
  beforeDestroy() {
    if (this.player !== null) {
      this.player.destroy()
      //   this.player.videoClear();
      this.player = null
    }
  },
}
</script>

<style lang="scss" scoped>
.watch-list {
  width: 100%;
  height: 100%;
  background-color: #383838;
  // border-radius: pcRem(20) 0 0 pcRem(20);
  color: #fff;
  font-size: 14px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 18px;
    color: #fff;
    background: #383838;
    flex: 0 0 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    margin-bottom: 10px;
    border-bottom: 1px solid #777777;
    .icon {
      width: 50px;
      height: auto;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .lists {
    flex: 1;
    overflow-y: auto;
    .TeamListCascader {
      width: 100%;
      h1 {
        font-size: 16px;
        padding: 10px;
      }
    }
    /deep/ {
      .ivu-collapse {
        background-color: #fcfcfc;
        border-left: none;
        border-top: 0;
        // .ivu-collapse-item {
        //   &:last-child {
        //     // border-bottom: 1px solid #dcdee2;
        //   }
        // }
        .ivu-collapse-header {
          height: 60px;
          line-height: 60px;
          font-size: 18px;
          white-space: nowrap;
          display: flex;
          align-items: center;
        }
        .ivu-collapse-content {
          padding: 0;
        }
        .ivu-collapse-content-box {
          padding: 0;
          // background-color: #fcfcfc;
        }
      }
    }

    .header {
      flex: 1;
      display: flex;
      align-items: center;
      .status {
        flex: 0 0 12px;
        height: 11px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .h-title {
        font-size: 14px;
        color: #fff;
      }
      .count {
        flex: 1;
        font-size: 12px;
        color: #fff;
        text-align: right;
        padding-right: 32px;
      }
    }
    .TeamChatbottom {
      width: 321px;
      height: 40px;
      margin: 20px 5px;
      position: relative;
      cursor: pointer;
      border: 1px solid #ebebeb;
      > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 18px;
          margin-right: 8px;
        }
        h1 {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          color: #fff;
          line-height: 40px;
        }
      }
    }
  }
}
.ivu-collapse {
  border-radius: 0;
  border: none;
}
/deep/.ivu-collapse > .ivu-collapse-item > .ivu-collapse-header {
  border-bottom: none;
}
/deep/.ivu-collapse > .ivu-collapse-item {
  border-top: none;
}
/deep/.PilotPlay-modal {
  .header {
    position: relative;
    span {
      display: block;
      font-size: 16px;
      width: 100%;
      text-align: center;
    }
    i {
      position: absolute;
      left: 10px;
      top: 50%;
      font-size: 24px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  .content {
    width: 100%;
    height: 600px;
    display: flex;
    overflow: hidden;
    border-radius: 10px;
    .list {
      padding: 10px 0;
      width: 20%;
      height: 100%;
      background-color: #ededed;
      overflow: auto;
      p {
        text-align: center;
        margin-top: 40px;
        font-size: 16px;
        color: #d0d0d0;
      }
      .select {
        border: 1px solid #57a3f3 !important;
      }
      .item {
        width: 200px;
        height: 60px;
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 6px;
        padding: 0 6px;
        cursor: pointer;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        border: 1px solid #fff;
        .head_portrait {
          height: 40px;
          width: 40px;
          border-radius: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
          }
        }
        span {
          width: 100px;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; //文本不换行，这样超出一行的部分被截取，显示...
        }
        i {
          font-size: 20px;
        }
      }
    }
    .list::-webkit-scrollbar {
      width: 0px;
    }
    .videoBox {
      width: 80%;
      height: 100%;
      background-color: #414141;
    }
  }
}
</style>
