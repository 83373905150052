<template>
  <div id="CesiumMap">
    <div class="center3DMap"
         id="center3DMap"></div>
  </div>
</template>

<script>
import Api from '@/utils/api.js';
import _ from 'lodash';
export default {
  name: 'CesiumMap',
  props: {
    config: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      showEntities_id_assemble: [], // 实体集合
    };
  },
  mounted() {
    //3D地图创建
    this.Init_map_cesium();
  },
  methods: {
    // 创建Cesium地图
    Init_map_cesium() {
      let _this = this;

      // 使用的Cesium提供的地图和地形数据，就必须申请账号和创建AccessToken
      Cesium.Ion.defaultAccessToken = Api.CesiumToken();
      //镜头默认范围(必须在viewer实例创建前设置)
      // 定位在中国上空
      Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
        112.970812,
        28.201272,
        112.989523,
        28.182814
      );

      let viewer = new Cesium.Viewer('center3DMap', {
        animation: false, //是否创建动画小器件，左下角仪表
        baseLayerPicker: false, //是否显示图层选择器
        fullscreenButton: false, //是否显示全屏按钮
        geocoder: false, //是否显示geocoder小器件，右上角查询按钮
        homeButton: true, //是否显示Home按钮
        infoBox: false, //是否显示信息框
        sceneModePicker: false, //是否显示3D/2D选择器
        selectionIndicator: false, //是否显示选取指示器组件
        timeline: false, //是否显示时间轴
        navigationHelpButton: false, //是否显示右上角的帮助按钮
        // 加载Cesium自带地图
        // imageryProvider: new Cesium.TileMapServiceImageryProvider({
        //   url: Cesium.buildModuleUrl('Assets/Textures/NaturalEarthII'),
        // }),
      });
      viewer.scene.globe.show = false; //不显示地球，这条和地球透明度选一个就可以
      viewer.camera.setView({
        //镜头的经纬度、高度。镜头默认情况下，在指定经纬高度俯视（pitch=-90）地球
        position: Cesium.Cartesian3.fromDegrees(119.286808, 26.053644, 500),
        //下面的几个方向正好反映默认值
        heading: Cesium.Math.toRadians(0),
        pitch: Cesium.Math.toRadians(-90),
        roll: Cesium.Math.toRadians(0),
      });
      // 开启地形深度检测
      viewer.scene.globe.depthTestAgainstTerrain = true;
      //加载地形
      //   viewer.terrainProvider = Cesium.createWorldTerrain();
      // 全局注册cesium(不能挂载到data里,数据劫持会造成地图卡顿)
      window.Viewer_live = viewer;

      // 去掉版权信息
      viewer.cesiumWidget.creditContainer.style.display = 'none';

      //天地图卫星影像
      // 加载本地地图=======================================
      // var arcgis_blue = viewer.imageryLayers.addImageryProvider(
      //     new Cesium.TileMapServiceImageryProvider({
      //       // 有的版本是用createTileMapServiceImageryProvider
      //       url: Api.TianDiTuMap(),
      //       fileExtension: 'png',
      //     })
      //   );

      // home定位到具体地点和高度(长沙)
      viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
        function (e) {
          e.cancel = true;
          //你要飞的位置
          viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
              _this.$Center[0],
              _this.$Center[1],
              //   112.989523,
              //   28.182814,
              30000
            ),
          });
        }
      );
      // 让地图到长沙
      viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          _this.$Center[0],
          _this.$Center[1],
          //   112.989523,
          //   28.182814,
          40000
        ),
      });

      //阻止摄像头到地下(还有缺陷)
      // viewer.clock.onTick.addEventListener(function () {
      //   if (viewer.camera.pitch > 0) {
      //     viewer.scene.screenSpaceCameraController.enableTilt = false;
      //   }
      // });
      //
      // var mousePosition, startMousePosition;
      // var handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      // handler.setInputAction(function (movement) {
      //   mousePosition = startMousePosition = Cesium.Cartesian3.clone(movement.position);
      //   handler.setInputAction(function (movement) {
      //     mousePosition = movement.endPosition;
      //     var y = mousePosition.y - startMousePosition.y;
      //     if (y > 0) {
      //       viewer.scene.screenSpaceCameraController.enableTilt = true;
      //     }
      //   }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      // }, Cesium.ScreenSpaceEventType.RIGHT_DOWN);
    },
    // 获取无人机颜色种类
    getPlaneIcon(color) {
      let icon;
      switch (color) {
        case '#FF685B':
          icon = new Cesium.Color(255 / 255, 104 / 255, 91 / 255, 1);
          break;
        case '#FF9500':
          icon = new Cesium.Color(255 / 255, 149 / 255, 0, 1);
          break;
        case '#4C98FF':
          icon = new Cesium.Color(76 / 255, 152 / 255, 1, 1);
          break;
        case '#61D76B':
          icon = new Cesium.Color(97 / 255, 215 / 255, 107 / 255, 1);
          break;
        case '#C179E5':
          icon = new Cesium.Color(193 / 255, 121 / 255, 229 / 255, 1);
          break;
        default:
          icon = new Cesium.Color(255 / 255, 104 / 255, 91 / 255, 1);
      }
      return icon;
    },
    // 加载 用户 无人机
    generateUserMarker(item) {
      var user_entities_id, plane_entities_id, line_entities_id;
      //若没有位置信息  则不在地图上显示用户位置
      if (!item.UserLongitude || !item.UserLatitude) {
        return;
      }

      console.log('加载 用户', item.name, item);
      let viewer = window.Viewer_live;
      // let img=item.head_icon_path || item.head_icon;
      // let color=
      // 转换坐标
      let billboardLocation = gcj02towgs84(
        Number(item.UserLongitude) || 0,
        Number(item.UserLatitude) || 0
      );
      let lng = billboardLocation[0];
      let lat = billboardLocation[1];
      // 飞手
      user_entities_id = 'user_' + item.user_id;
      this.showEntities_id_assemble.push(user_entities_id);
      viewer.entities.add({
        id: user_entities_id,
        name: '飞手',
        position: Cesium.Cartesian3.fromDegrees(lng, lat, 20),
        polyline: {
          // 画线
          positions: Cesium.Cartesian3.fromDegreesArrayHeights([
            lng,
            lat,
            0,
            lng,
            lat,
            20,
          ]),
          width: 1,
          material: new Cesium.PolylineOutlineMaterialProperty({
            color: Cesium.Color.RED,
            outlineWidth: 0,
            // outlineColor : Cesium.Color.BLACK
          }),
        },
        billboard: {
          //图标
          image: require('../../../assets/img/aaa.png'),
          // image : item.head_icon_path,
          scale: 0.3,
        },
        label: {
          //定义标签title
          text: '飞手 : ' + item.name,
          font: 'normal 14px MicroSoft YaHei',
          scale: 0.8,
          style: Cesium.LabelStyle.FILL_AND_OUTLINE,
          color: Cesium.Color.WHITE,
          // color: this.getPlaneIcon(item.comment),
          showBackground: true,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          pixelOffset: new Cesium.Cartesian2(0, -30),
        },
        item: item,
      });

      // console.log(111);
      // 无人机
      if (item.positionList.length !== 0) {
        // console.log(222);
        // console.log("加载 无人机",item.name);
        let L = item.positionList.length;
        let billboardLocation2 = gcj02towgs84(
          Number(item.positionList[L - 1][0]) || 0,
          Number(item.positionList[L - 1][1]) || 0
        );
        let lng2 = billboardLocation2[0];
        let lat2 = billboardLocation2[1];
        let position = Cesium.Cartesian3.fromDegrees(
          lng2,
          lat2,
          Number(item.DroneAltitude)
        );
        //因为模型出来是默认指向西所以要加90,
        let heading = Cesium.Math.toRadians(Number(item.DroneYaw) + 90); //绕垂直于地心的轴旋转 (航向角)
        let pitch = Cesium.Math.toRadians(~Number(item.DronePitch)); //绕纬度线旋转 (俯仰角)
        let roll = Cesium.Math.toRadians(~Number(item.DroneRoll)); //绕经度线旋转 (横滚角)
        var hpRoll = new Cesium.HeadingPitchRoll(heading, pitch, roll);
        var orientation = Cesium.Transforms.headingPitchRollQuaternion(
          position,
          hpRoll
        );
        // 加载glb==================
        plane_entities_id = 'UAV_' + item.user_id;
        this.showEntities_id_assemble.push(plane_entities_id);
        viewer.entities.add({
          id: plane_entities_id,
          name: '无人机',
          position: position,
          // 模型方向
          orientation: orientation,
          model: {
            // 模型路径
            uri: require('@/assets/glb/ccc.glb'),
            // 模型最小刻度
            // minimumPixelSize: 128,
            //		                    // 模型最大刻度
            //                             maximumSize: 128,
            //设置模型最大放大大小
            // maximumScale : 200,
            // 模型是否可见
            show: true,
            // 模型轮廓颜色
            // silhouetteColor:Cesium.Color.WHITE,
            // 模型颜色  ，这里可以设置颜色的变化
            // color: Cesium.Color.LIME ,
            // 仅用于调试，显示魔仙绘制时的线框
            debugWireframe: false,
            // 仅用于调试。显示模型绘制时的边界球。
            debugShowBoundingVolume: false,
            scale: 10,
            //是否运行模型中的动画效果
            runAnimations: false,
          },

          label: {
            //定义标签title
            text:
              'H : ' +
              item.DroneAltitude +
              'm' +
              '\n' +
              'OP :' +
              item.power +
              '\n' +
              item.name,
            font: 'normal 14px MicroSoft YaHei',
            scale: 0.8,
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            color: Cesium.Color.WHITE,
            // color: this.getPlaneIcon(item.comment),
            showBackground: true,
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            pixelOffset: new Cesium.Cartesian2(0, -30),
          },
          item: item,
        });

        //飞手和无人机之间的连线
        // line_entities_id="line_"+item.user_id;
        // this.showEntities_id_assemble.push(line_entities_id);
        // viewer.entities.add({
        //     id:line_entities_id,
        //     name : '连接线',
        //     polyline : {// 画线
        //         positions : Cesium.Cartesian3.fromDegreesArrayHeights(
        //             [ lng,lat,20, lng2,lat2,Number(item.DroneAltitude)]
        //         ),
        //         width : 1,
        //         material : new Cesium.PolylineOutlineMaterialProperty({
        //             color : this.getPlaneIcon(item.comment),
        //             outlineWidth : 0,
        //             // outlineColor : Cesium.Color.BLACK
        //         })
        //     }
        // });
      }
    },
    // 更新用户 无人机 数据
    updateUserMarker(ele, ID, UAVlocation) {
      // viewer.entities.getById(id).position
      let viewer = window.Viewer_live;
      var type = ID.split('_')[0].toString();
      var id = ID.split('_')[1];

      // 转换坐标
      let billboardLocation = gcj02towgs84(
        Number(ele.UserLongitude) || 0,
        Number(ele.UserLatitude) || 0
      );
      let lng = billboardLocation[0];
      let lat = billboardLocation[1];

      switch (type) {
        case 'user': {
          viewer.entities.getById(ID).position = Cesium.Cartesian3.fromDegrees(
            lng,
            lat,
            20
          );
          viewer.entities.getById(ID).polyline.positions =
            Cesium.Cartesian3.fromDegreesArrayHeights([
              lng,
              lat,
              0,
              lng,
              lat,
              20,
            ]);
          break;
        }
        case 'UAV': {
          if (ele.positionList.length !== 0) {
            let billboardLocation2;

            // billboardLocation2=gcj02towgs84(UAVlocation.lng,UAVlocation.lat);

            let L = ele.positionList.length;
            billboardLocation2 = gcj02towgs84(
              Number(ele.positionList[L - 1][0]) || 0,
              Number(ele.positionList[L - 1][1]) || 0
            );

            let lng2 = billboardLocation2[0];
            let lat2 = billboardLocation2[1];
            let position = Cesium.Cartesian3.fromDegrees(
              lng2,
              lat2,
              Number(ele.DroneAltitude)
            );
            //因为模型出来是默认指向西所以要加90,
            let heading = Cesium.Math.toRadians(Number(ele.DroneYaw) + 90); //绕垂直于地心的轴旋转 (航向角)
            let pitch = Cesium.Math.toRadians(Number(ele.DronePitch)); //绕纬度线旋转 (俯仰角)
            let roll = Cesium.Math.toRadians(Number(ele.DroneRoll)); //绕经度线旋转 (横滚角)
            var hpRoll = new Cesium.HeadingPitchRoll(heading, pitch, roll);
            var orientation = Cesium.Transforms.headingPitchRollQuaternion(
              position,
              hpRoll
            );

            viewer.entities.getById(ID).position = position;
            viewer.entities.getById(ID).orientation = orientation;
            viewer.entities.getById(ID).label.text =
              'H : ' +
              ele.DroneAltitude +
              'm' +
              '\n' +
              'OP :' +
              ele.power +
              '\n' +
              ele.name;
          } else {
            viewer.entities.removeById(ID);
            this.showEntities_id_assemble =
              this.showEntities_id_assemble.filter((entitie) => entitie !== ID);
          }
          break;
        }
        // case 'line':{
        //     if(ele.positionList.length!==0){
        //         let L = ele.positionList.length;
        //         let billboardLocation2=gcj02towgs84(
        //             Number(ele.positionList[L-1][0])|| 0,
        //             Number(ele.positionList[L-1][1])|| 0
        //         );
        //         let lng2=billboardLocation2[0];
        //         let lat2=billboardLocation2[1];
        //
        //         viewer.entities.getById(ID).polyline.positions=Cesium.Cartesian3.fromDegreesArrayHeights(
        //             [ lng,lat,20, lng2,lat2,Number(ele.DroneAltitude)]
        //         );
        //     }else {
        //         viewer.entities.removeById(ID);
        //         this.showEntities_id_assemble = this.showEntities_id_assemble.filter(
        //             entitie => entitie !== ID
        //         );
        //     }
        //     break
        // }
      }
    },
    // 清除不在线的飞手和无人机
    clearAllNotOnLinePlanes() {
      let viewer = window.Viewer_live;
      this.showEntities_id_assemble.forEach((ele, ind) => {
        if (
          this.config.onLineList.findIndex(
            (item) => item.user_id == ele.split('_')[1].toString()
          ) === -1
        ) {
          // this.map.remove(ele);
          viewer.entities.removeById(ele);
          this.showEntities_id_assemble.splice(ind, 1);
        }
      });
    },
    // 更新数据
    drawAirPlanes() {
      // 清除不在线上的实体
      this.clearAllNotOnLinePlanes();

      // console.log("this.config.onLineList",this.config.onLineList);
      this.config.onLineList.forEach((item) => {
        let entities = this.showEntities_id_assemble.filter(
          (entitie) =>
            entitie.split('_')[1].toString() == item.user_id.toString()
        );
        if (!entities.length) {
          this.generateUserMarker(item);
        } else {
          entities.forEach((ID) => {
            // if(ID.split("_")[0].toString() == 'UAV'){
            //     let L = item.positionList.length;
            //     // 倒数第一个点
            //     let lng1=Number(item.positionList[L-1][0])|| 0;
            //     let lat1=Number(item.positionList[L-1][1])|| 0;
            //     // 倒数第二个点
            //     let lng2=Number(item.positionList[L-2][0])|| 0;
            //     let lat2=Number(item.positionList[L-2][1])|| 0;
            //     //得到差值
            //     let lngDifference=(lng1-lng2)/4;
            //     let latDifference=(lat1-lat2)/4;
            //     for(var i=0,len=4; i<len; i++){
            //         let ind=i+1;
            //         setTimeout(()=>{
            //             let UAVlocation={
            //                 lng:lngDifference*ind+lng2,
            //                 lat:latDifference*ind+lat2
            //             };
            //             this.updateUserMarker(item, ID,UAVlocation);
            //         },500*ind)
            //     }
            //     // let UAVlocation={
            //     //     lng:lng1,
            //     //     lat:lat1
            //     // };
            //     // this.updateUserMarker(item, ID,UAVlocation);
            // }else {
            //     this.updateUserMarker(item, ID);
            // }
            // if(item==undefined){
            //     return;
            // }
            this.updateUserMarker(item, ID);
          });
        }
      });
    },
  },
  watch: {
    // 通知配置
    config: {
      deep: true,
      handler: function (val) {
        // socket数据更新 地图同时更新
        this.drawAirPlanes();
      },
    },
    showEntities_id_assemble(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped lang="scss">
#CesiumMap {
  width: 100%;
  height: 100%;
  position: absolute;
  .center3DMap {
    width: 100%;
    height: 100%;
    background-color: #d6d6d6;
  }
}
</style>
