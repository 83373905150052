<template>
  <div class="watch">
    <!--人员列表区域-->
    <div class="watchBox"
         v-show="!rapidShou">
      <!--3D地图切换图标-->
      <div :id="Shou3D ? 'Map3D' : ''"
           class="MapSwitch"
           ref="MapSwitch"
           @click="Map3DSwitch"
           :style="dynamicStyle">
        <img src="../../../assets/img/Icon/Switch3dMap.png"
             alt="" />
      </div>
      <!--高德地图-->
      <Map :config="mapConfig"
           @sendMsg="sendMsgHandler"
           @sendCommander="sendCommanderHandler"
           ref="commonMap"
           v-show="!Shou3D"
           @sendSocketMsg="sendSocketMsg"
           :targetPoint="targetPoint" />
      <!--Cesium地图-->
      <CesiumMap :config="mapConfig"
                 ref="commonCeaiumMap"
                 v-show="Shou3D" />
      <folder-modal ref="folder"
                    :config="folderConfig"
                    @toggleFolder="toggleFolder">
        <WatchLists ref="watchLists"
                    :dataConfig="watchData"
                    :TeamChatData="TeamChatData"
                    @fetchData="fetchData"
                    @changeTeamData="changeTeamData"
                    @sendSocketMsg="sendSocketMsg"
                    @mapCenterToUser="mapCenterToUser" />
      </folder-modal>
      <Spin size="large"
            fix
            v-if="spinShow"></Spin>
      <div class="userInfoBox"
           v-show="userInfoBoxShow"
           :style="dynamicStyle">
        <div class="name">
          <img :src="userInfoBox.head_icon_path" /><span :title="userInfoBox.name">{{
            userInfoBox.name
          }}</span>
        </div>
        <div class="unit"
             :title="userInfoBox.teamName">{{userInfoBox.teamName}}</div>
        <div class="account">
          <img src="@/assets/img/statistics/people_siren.png" /><span>{{ userInfoBox.account }}</span>
        </div>
        <div class="policeNum"
             :title="userInfoBox.police_license">
          <img src="@/assets/img/statistics/people_number.png" /><span>{{ userInfoBox.police_license }}</span>
        </div>
        <div class="phone"
             :title="userInfoBox.mobile_phone">
          <img src="@/assets/img/statistics/people_phone.png" /><span>{{ userInfoBox.mobile_phone }}</span>
        </div>
        <div class="btns"><span @click="closeUserInfoBox">关闭--</span><span @click="chattoUser">---聊天</span></div>
      </div>
    </div>

    <!--快拼任务区域-->
    <div class="rapid"
         v-if="rapidShou">
      <!--高德地图-->
      <Map :config="rapidMapConfig"
           :rapidInfo="rapidGetTheNodInfo"
           @sendMsg="RapidSendMsg"
           ref="rapidMap"
           @sendSocketMsg="sendSocketMsg" />
      <folder-modal ref="folder"
                    :config="folderConfig"
                    @toggleFolder="toggleFolder2">
        <rapidBiuldFigure ref="rapid"
                          :config="rapidData"
                          :info="rapidGetTheNodInfo"
                          :rapidFeedbackMsg="rapidFeedbackMsg"
                          @sendSocketMsg="sendSocketMsg" />
      </folder-modal>
      <!-- <div class="videoBox"
           id="videoBox"
           v-if="videoBoxShou"></div> -->
      <video id="videoBox"
             autoplay
             style="width= 100%; height=100%; object-fit: cover;background-color: #333333;"
             @click.capture.stop=""></video>
    </div>
  </div>
</template>

<script>
import Map from '@/components/statistics/commonComponents/map.vue';
import CesiumMap from './CesiumMap.vue';
import folderModal from '@/components/statistics/commonComponents/folderModal.vue';
import WatchLists from '@/components/live/watch/watch-lists.vue';
import rapidBiuldFigure from '@/components/live/watch/rapidBiuldFigure.vue';
import Avatar from '@/assets/img/statistics/message.png';
import Api from '@/utils/api.js';
import Socket from '@/utils/socket.mixin.js';

export default {
  name: 'create-app',
  mixins: [Socket],
  components: {
    Map,
    CesiumMap,
    folderModal,
    WatchLists,
    rapidBiuldFigure,
  },
  created() {},
  computed: {
    // 右上角3D按钮的位置
    dynamicStyle() {
      var _this = this;
      return {
        left: (_this.mapConfig.toolsMovingDistance || 0) + 60 + 'px',
      };
    },
  },
  mounted() {
    let _this = this;
    this.user_info = JSON.parse(sessionStorage.getItem('user_info'));
    this.fetchData();
    //快速拼图区域++++++++++++++++++++++++++++++++++++++++++
    //进入快速拼图界面方法
    this.rapidMapConfig.rapidData = this.rapidData;
    this.$_bus.$off('showRapidHandler');
    this.$_bus.$on('showRapidHandler', function (info) {
      _this.showRapidHandler(info);
    });
    //回到直播页面
    this.$_bus.$off('RapidBackHandler');
    this.$_bus.$on('RapidBackHandler', function () {
      _this.RapidBackHandler();
    });
    //更新数据
    this.$_bus.$off('updateRapid');
    this.$_bus.$on('updateRapid', function (data) {
      _this.updateRapid(data);
    });
    //快拼直播
    this.$_bus.$off('RapidVideo');
    this.$_bus.$on('RapidVideo', function (data) {
      _this.RapidVideo(data);
    });
    this.TeamChatDefault.name = this.user_info.team_name;
    this.TeamChatData = JSON.parse(JSON.stringify(this.TeamChatDefault)); //深拷贝
    this.TeamChatData = Object.assign({}, this.TeamChatData, {
      isTeam: true,
      user_id: this.user_info.team_id,
      name: this.user_info.team_name,
      team_id: this.user_info.team_id,
    });
  },
  data() {
    return {
      spinShow: true,
      mapConfig: {
        toolsMovingDistance: 345,
        tools: [
          // 'command',
          'marker',
          'search',
          'measure',
          'hierarchy',
          'zoomIn',
          'zoomOut',
        ],
        onLineList: [],
        updateBySocket: false,
        isLive: true, // 显示名称控件
      },
      folderConfig: {
        width: 341,
        backgroundColor: '#FCFCFC',
        direction: 'left',
      },
      watchData: {
        type: 'normal',
        title: '用户',
        data: [],
        onLineCount: 0,
      },
      keyTemp: {
        UserLongitude: '',
        UserLatitude: '',
        IsPublish: 0,
        PUBLISH_NAME: '',
        DroneLongitude: '',
        DroneLatitude: '',
        head_icon_path: '',
        speed: '',
        DroneAltitude: '',
        DronePitch: '',
        DroneRoll: '',
        remote: '',
        figure_pass: '',
        remote_power: '',
        dianliang: '',
        temperature: '',
        voltage: '',
        // hadNewMsg: 0,
        NewMsg: 0,
        msg_content: '',
        from_id: '',
        msg_type: '',
        from_name: '',
        time_line: '',
        target_id: '',
        target_type: '',
        power: '',
        isPlayingLive: false,
        airPlaneAcceptedSpeakControlStatus: 'none', //无人机是否已经接收喊话控制 none waitingAccept accepted
        airPlaneAcceptedControlStatus: 'none', //无人机是否已经接收远程控制 none waitingAccept accepted
      },
      Shou3D: false,
      // 创建快速拼图任务
      rapidShou: false,
      rapidGetTheNodInfo: null, //快拼被选中的用户数据
      rapidMapConfig: {
        toolsMovingDistance: 340,
        tools: ['search', 'measure', 'hierarchy', 'zoomIn', 'zoomOut'],
        rapidStatus: false,
        rapidData: null,
      },
      rapidData: {
        task3DModel: '',
        taskName: '',
        id: '',
        activeMarker: -1,
        airLinePoints: 0,
        ariLineLength: 0, //航线长度
        flyingExpectTime: 0, //预计飞行时间
        speed: 8, //飞行速度 (m/s)
        height: 100, //飞行高度 (m)
        targetHeight: 0, //目标高度 (m)
        relativeHeight: 100, //相对高度 (m)
        select: 1, //任务完成动作
        pointHeight: 50,
        pointAngle: 0, //云台俯仰角度 (°)
        airArea: 0, //区域面积
        photoGraphTimes: 0, //照片数量
        courseOverlapRate: 80, //航线重叠率 (%)
        sideOverlapRate: 70, //旁向重叠率 (%)
        courseAngle: 0, //航线角度
        points: [],
        waypoint: [],
        defaultFov: 84,
        rapidPathArray: [],
      },
      rapidFeedbackMsg: null, //快拼ios端反馈信息
      videoBoxShou: false,
      player: null,
      targetPoint: {},
      TeamChatDefault: {
        //团队聊天默认聊天
        DroneAltitude: '',
        DroneLatitude: '',
        DroneLongitude: '',
        DronePitch: '',
        DroneRoll: '',
        IsPublish: 0,
        NewMsg: 0,
        PUBLISH_NAME: '',
        UserLatitude: '',
        UserLongitude: '',
        airPlaneAcceptedControlStatus: 'none',
        airPlaneAcceptedSpeakControlStatus: 'none',
        dianliang: '',
        figure_pass: '',
        from_id: '',
        from_name: '',
        // hadNewMsg: 0,
        head_icon: '',
        head_icon_path: '',
        isPlayingLive: false,
        is_online: 0,
        msgList: [],
        msg_content: '',
        msg_type: '',
        name: '团队',
        onLineCount: 0,
        positionList: [],
        power: '',
        remote: '',
        remote_power: '',
        selected: false,
        speed: '',
        target_id: '',
        target_type: '',
        temperature: '',
        time_line: '',
        user_id: 0,
        voltage: '',
        team_id: '',
      },
      TeamChatData: {},
      userInfoBoxShow: false,
      userInfoBox: {}, // 点击在线飞手头像时地图右下角显示的信息框
      user_info: {},
    };
  },
  methods: {
    // 点击在线飞手头像
    mapCenterToUser(data) {
      if (!data.UserLongitude || !data.UserLatitude) {
        this.$notice.error({
          title: '当前用户未推送位置信息!',
        });
        return;
      }
      let position = [
        parseFloat(data.UserLongitude),
        parseFloat(data.UserLatitude),
      ];
      // 显示飞手信息框
      //   this.userInfoBoxShow = true;
      //   this.userInfoBox = data;
      // 地图定位到用户的位置
      this.$refs['commonMap'].backCenter(position);
    },
    // 关闭飞手信息窗
    closeUserInfoBox() {
      this.userInfoBoxShow = false;
    },
    chattoUser() {
      //   console.log('watch-index.vue---chattoUser-userInfoBox', this.userInfoBox);
      this.$_bus.$emit('showChatHandler', this.userInfoBox);
    },
    // 发送socket消息
    sendSocketMsg(data, notice = true) {
      // console.log("socker", data);
      this.sendMessage(data);
      if (data.dataType) {
        return;
      }
      this.$notice.success({
        title: '消息发送成功!',
      });
    },
    // 更新位置信息
    updateEleLocationInfo(ele, data) {
      /**
       * ele--watchData遍历的各项
       * data -- ws传入的在线数据
       */
      // console.log("watchData遍历的各项", ele);
      // console.log("ws传入的在线数据", data);
      Object.keys(data).forEach((key) => {
        ele[key] = data[key];
      });
      ele.is_online = 1;
      if (!ele.positionList) {
        ele.positionList = [];
      } else {
        if (
          data.DroneLongitude &&
          data.DroneLatitude &&
          data.DroneLongitude !== 'NaN' &&
          data.DroneLatitude !== 'NaN' &&
          data.DroneLongitude !== '0' &&
          data.DroneLatitude !== '0'
        ) {
          ele.positionList.push([
            parseFloat(data.DroneLongitude),
            parseFloat(data.DroneLatitude),
          ]);
        }
      }
      // (ele.positionList || (ele.positionList = [])).push([parseFloat(data.DroneLongitude) || 0, parseFloat(data.DroneLatitude) || 0])
      this.updateOnLineCount(this.watchData);
    },
    // 更新新消息
    updateMsgInfo(ele, data, type = 'text') {
      Object.keys(data).forEach((key) => {
        ele[key] = data[key];
      });
      console.log('更新新消息', type, data);
      ele.is_online = 1;
      if (data.offLineMsg > 0) {
        ele.NewMsg = data.offLineMsg;
      } else {
        if (!this.$refs['watchLists'].showChat) {
          // ele.hadNewMsg = 1;
          ele.NewMsg++;
        } else {
          ele.NewMsg = 0;
        }
      }
      if (ele.from_id.toString() !== this.user_info.user_id.toString()) {
        ele.msgList.push({
          avatar:
            ele.from_id.toString() === this.user_info.user_id.toString()
              ? this.user_info.head_icon
              : ele.head_icon,
          role:
            ele.from_id.toString() === this.user_info.user_id.toString()
              ? 'self'
              : 'remote',
          msg_type: type == 'voice' ? 'voice' : data.msg_type,
          content: data.msg_content,
          isPlayingVoice: false,
          voiceTimeLength: data.msg_attribute,
          voiceId: ele.msgList.length - 1,
        });
        console.log(ele.msgList);
      }
      this.updateOnLineCount(this.watchData);
    },
    // 团队更新新消息
    updateTeamMsgInfo(ele, data) {
      ele.is_online = 1;
      if (!this.$refs['watchLists'].showChat) {
        // ele.hadNewMsg = 1;
        ele.NewMsg++;
      } else {
        ele.NewMsg = 0;
      }
      if (ele.user_id.toString() !== this.user_info.user_id.toString()) {
        ele.msgList.push({
          avatar:
            ele.user_id.toString() === this.user_info.user_id.toString()
              ? this.user_info.head_icon
              : data.head_icon,
          role:
            ele.user_id.toString() === this.user_info.user_id.toString()
              ? 'self'
              : 'remote',
          msg_type: data.msg_type || '',
          content: data.msg_content,
          isPlayingVoice: false,
          voiceTimeLength: data.msg_attribute,
          voiceId: ele.msgList.length - 1,
          name:
            ele.user_id.toString() === this.user_info.user_id.toString()
              ? this.user_info.name
              : data.name,
          user_id: data.user_id,
        });
      }

      this.updateOnLineCount(this.watchData);
    },
    // 更新在线状态
    updateOnLineInfo(ele, data) {
      ele.is_online = 0;
      ele.positionList = [];
      this.updateOnLineCount(this.watchData);
    },
    // 更新是否接受了控制
    updateSpeakContorlStatus(ele, data) {
      ele.is_online = 1;
      if (data.msg_type === 1) {
        this.$notice.warning({
          title: '无人机拒绝接受喊话控制!',
        });
        ele.airPlaneAcceptedSpeakControlStatus = 'none';
      }
      if (
        ele.airPlaneAcceptedSpeakControlStatus === 'waitingAccept' &&
        data.msg_type === 2
      ) {
        this.$notice.success({
          title: '无人机同意接受喊话控制!',
        });
        ele.airPlaneAcceptedSpeakControlStatus = 'accepted';
      }
      // if (data.msg_type === 3) {
      //   this.$notice.warning({
      //     title: '无人机关闭喊话控制!'
      //   })
      //   ele.airPlaneAcceptedSpeakControlStatus = 'none';
      // }
      if (data.msg_type === 5) {
        this.$notice.success({
          title: '无人机喊话器播放成功!',
        });
      }
      this.updateOnLineCount(this.watchData);
    },
    // 更新是否接受了控制
    updateContorlStatus(ele, data) {
      ele.is_online = 1;
      if (data.dataType === 1) {
        this.$notice.warning({
          title: '无人机拒绝接受远程控制!',
        });
        // ele.airPlaneAcceptedControlStatus = "none";
      }
      if (
        ele.airPlaneAcceptedControlStatus === 'waitingAccept' &&
        data.dataType === 17
      ) {
        this.$notice.success({
          title: '无人机同意接受远程控制!',
        });
        ele.airPlaneAcceptedControlStatus = 'accepted';
      }
      if (data.msg_type === 3) {
        this.$notice.warning({
          title: '无人机挂断控制!',
        });
        // ele.airPlaneAcceptedControlStatus = "none";
      }
      this.updateOnLineCount(this.watchData);
      // 确定watchData被改变
    },
    // 统计更新在线个数
    updateOnLineCount(data, updateBySocket = true) {
      this.mapConfig.onLineList = [];
      data.onLineCount = 0;
      data.data.forEach((ele) => {
        if (!!ele.is_online) {
          ++data.onLineCount;
          if (
            !this.mapConfig.onLineList.some(
              (item) => item.user_id === ele.user_id
            )
          ) {
            this.mapConfig.onLineList.push(ele);
          }
        }
      });
      this.mapConfig.updateBySocket = updateBySocket;
      this.reRankDataByOnLine();
    },
    // 拓展子组件要用的属性到目前对象上面
    expandWatchData(data) {
      // data.onLineCount = 0;
      data.data.forEach((ele) => {
        ele.onLineCount = 0;
        Object.keys(this.keyTemp).forEach((key) => {
          ele[key] = this.keyTemp[key];
        });
        ele.positionList = [];
        ele.msgList = [];
        ele.selected = false;
      });
    },
    // 更新位置
    updateLocation(data) {
      try {
        this.watchData.data.forEach((ele) => {
          if (
            ele.user_id &&
            data.uid &&
            ele.user_id.toString() === data.uid.toString()
          ) {
            if (
              data.UserLatitude &&
              data.UserLongitude &&
              data.UserLatitude !== 'NaN' &&
              data.UserLongitude !== 'NaN'
            ) {
              this.updateEleLocationInfo(ele, data);
              // console.log("更新位置_2", ele);
            }
          }
        });
      } catch (e) {
        console.log(e);
        this.$Notice.success({
          title: '更新位置失败!',
          desc: e.toString(),
        });
      }
    },
    // 更新消息
    updateMsg(data) {
      console.log('更新消息', data);
      try {
        if (data.offLineMsg > 0) {
          data.data.from_id = data.uid;
        }
        this.watchData.data.forEach((ele) => {
          if (
            ele.user_id &&
            data.data.from_id &&
            ele.user_id.toString() === data.data.from_id.toString()
          ) {
            if (data.data.msg_type == 'user_local') {
              // 更新目标点
              this.targetPoint = data.data;
            } else {
              this.updateMsgInfo(ele, data.data);
            }
          }
        });
      } catch (e) {
        console.log(e);
        this.$Notice.error({
          title: '更新消息失败!',
          desc: e.toString(),
        });
      }
    },
    // 更新团队聊天消息
    updateTeamMsg(data) {
      //   console.log('团队聊天消息1', data);
      if (data.code != 1) {
        this.$Notice.error({
          title: '更新消息失败!',
          desc: data,
        });
        return;
      }
      //   console.log('TeamChatData 发消息者的用户信息--------', this.TeamChatData);
      //   console.log('服务器返回的teamid', data.data.team_id);
      //   console.log('服务器返回的data.user_id', data.data.user_id);
      //   console.log('本地的this.user_info.user_id', this.user_info.user_id);

      try {
        if (
          this.TeamChatData.user_id == data.data.team_id &&
          data.data.user_id != this.user_info.user_id
        ) {
          this.updateTeamMsgInfo(this.TeamChatData, data.data);
        }
      } catch (e) {
        console.log(e);
        this.$Notice.error({
          title: '更新消息失败!',
          desc: e.toString(),
        });
      }
    },
    // 更新喊话控制
    updateSpeakControl(data) {
      try {
        this.watchData.data.forEach((ele) => {
          if (
            ele.user_id &&
            data.data.from_id &&
            ele.user_id.toString() === data.data.from_id.toString()
          ) {
            this.updateSpeakContorlStatus(ele, data.data);
          }
        });
      } catch (e) {
        console.log(e);
        this.$Notice.success({
          title: '更新喊话控制信息失败!',
          desc: e.toString(),
        });
      }
    },
    // 更新远程控制
    updateControl(data) {
      // console.log(" 更新远程控制", data);

      try {
        this.watchData.data.forEach((ele) => {
          if (
            ele.user_id &&
            data.from_id &&
            ele.user_id.toString() === data.from_id.toString()
          ) {
            console.log(' 更新远程控制状态，ws发过来的信息', data);
            // console.log(ele);
            this.updateContorlStatus(ele, data);
          }
        });

        // 群体飞控数据状态更新
        this.mapConfig.onLineList.forEach((e, ind) => {
          if (e.user_id && data.from_id && e.user_id == data.from_id) {
            // console.log("群体飞控数据状态更新", data.dataType);
            if (data.dataType === 1) {
              this.mapConfig.onLineList[ind].airPlaneAcceptedControlStatus1 =
                'none';
              // console.log("群体飞控接受了远程控制");
            }
            if (
              this.mapConfig.onLineList[ind].airPlaneAcceptedControlStatus1 ===
                'waitingAccept' &&
              data.dataType === 17
            ) {
              this.mapConfig.onLineList[ind].airPlaneAcceptedControlStatus1 =
                'accepted';
              // console.log("群体飞控接受了远程控制");
            }
          }
        });
      } catch (e) {
        console.log(e);
        this.$Notice.success({
          title: '更新远程指挥信息失败!',
          desc: e.toString(),
        });
      }
      // console.log("更新远程控制", this.watchData);
    },
    // 更新在线
    updateOnLine(data) {
      //   console.log('watchData', this.watchData);
      try {
        this.watchData.data.forEach((ele, i) => {
          if (ele.user_id == data.client_id) {
            ele.is_online = 0;
          }
          if (
            ele.user_id &&
            data.uid &&
            ele.user_id.toString() === data.uid.toString()
          ) {
            this.updateOnLineInfo(ele, data);
          }
        });
      } catch (e) {
        console.log(e);
        this.$Notice.success({
          title: '更新在线状态失败!',
          desc: e.toString(),
        });
      }
    },

    // 消息类别处理
    socketMsgHandler(data) {
      // data  是回调函数的e.data
      // console.log("消息类别处理_2", this.watchData.data);
      // kick_off 是被app挤掉了
      //   console.log('收到ws给的消息了', data);
      let type = data.msg || data.type;
      switch (type) {
        case 'location':
          // 更新位置
          this.updateLocation(data);
          //快拼界面传递数据
          if (this.rapidShou) {
            this.RapidSocketMSG(data);
          }
          break;
        case 'user_msg':
          this.updateMsg(data);
          break;
        case 'team_chat':
          this.updateTeamMsg(data);
          break;
        case 'send_Speaker':
          this.updateSpeakControl(data);
          break;
        case 'control':
          console.log('index更新远程控制信息', data);
          this.updateControl(data);
          break;
        case 'kick_off':
          this.kickOff(data);
          break;
        case 'off_line':
          this.updateOnLine(data);
          break;
        case 'up_online':
          this.userOnLine(data);
          break;
        case 'voice':
          console.log('收到ws发的语音消息了');
          this.updateVoiceChat(data);
          break;
        default:
          return;
      }
    },
    updateVoiceChat(data) {
      this.watchData.data.forEach((ele) => {
        if (
          ele.user_id &&
          data.data.from_id &&
          ele.user_id.toString() === data.data.from_id.toString()
        ) {
          this.updateMsgInfo(ele, data.data, 'voice');
        }
      });
    },
    userOnLine(data) {
      this.watchData.data.forEach((el) => {
        if (el.user_id == data.uid) el.is_online = 1;
        this.updateOnLineCount(this.watchData);
      });
    },
    kickOff(data) {
      this.$Notice.info({
        title: '提示',
        desc: data.content,
      });
      this.$router.push('/login');
    },
    // 发送自己的定位消息
    connectSocket() {
      let param = {
        type: 'location', //消息标识
        sb: '1',
        uid: this.user_info.user_id,
        name: this.user_info.name,
        account: this.user_info.account,
        user_type: this.user_info.type,
        head_icon_path: this.user_info.head_icon,
        DroneLatitude: '',
        DroneLongitude: '',
        DroneYaw: '',
        DroneAltitude: '',
        HomeLatitude: '',
        HomeLongitude: '',
        PUBLISH_NAME: '',
        UserLatitude: '',
        UserLongitude: '',
        PointLongitude: '',
        PointLatitude: '',
        DRONETYPE: '',
        DRONEMODEL: '',
        // pm_first_id: '',
        Client: '1',
        address: '',
        // pm_second_id: this.pm_id,
        track_tag: 0,
        track_id: 0,
        highly: '',
        distance: '',
        PM: '',
        vertical_speed: '',
        horizontal_velocity: '',
        power: '',
        PM10: '',
        Wendu: '',
        Shidu: '',
        Jiaquan: '',
        TVOC: '',
        IsPublish: 0,
        sendIndex: 0,
        run_time: '',
        satellite_signal: 0,
        team_id: '',
        run_time: '',
      };
      this.connectWebsocket(
        Api.getWebSocketUrl() + this.userInfo.user_id,
        param,
        this.socketMsgHandler
      );
    },
    // generateListMap(arr = []) {
    //   arr.forEach((ele, index) => {
    //     (ele.teamuser || (ele.teamuser = [])).forEach((ele1, index1) => {
    //       this.listMap[ele1.user_id] = {
    //         proIndex: index,
    //         teamIndex: index1
    //       }
    //     })
    //   })
    // },
    // 指点飞行处理
    sendMsgHandler(sendObj) {
      let params = {
        target_id: sendObj.selectUids,
        type: sendObj.type,
        local_data: [
          {
            lat: sendObj.latitude,
            lng: sendObj.longitude,
            height: sendObj.height,
          },
        ],

        // altitude: sendObj.height,
        // longitude: sendObj.longitude,
        // latitude: sendObj.latitude,
        tag: sendObj.markerType, // 目标点类型
        targetName: sendObj.targetName, // 标注点名称
        targetRemarks: sendObj.targetRemarks, // 标注点备注
      };
      this.$post(Api.sendLocation(), params)
        .then((res) => {
          // console.log(res);
          if (res.code === 1) {
            this.$Notice.success({
              title: '发送成功!',
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notice.error({
            title: '发送失败!',
            desc: err.toString(),
          });
        });
    },
    // 指挥官目标点处理
    sendCommanderHandler(sendObj) {
      let params = {
        target_id: sendObj.selectUids,
        type: sendObj.type,
        local_data: sendObj.local_data,
        tag: sendObj.markerType, // 目标点类型
      };
      console.log('指挥官目标点', params);
      this.sendSocketMsg(params, false);
      //   this.$post(`/android/Commander/sendCommander`, params)
      //     .then((res) => {
      //       if (res.code === 1) {
      //         this.$Notice.success({
      //           title: '发送成功!',
      //         });
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       this.$notice.error({
      //         title: '发送失败!',
      //         desc: err.toString(),
      //       });
      //     });
    },
    // 在线的顶上来
    reRankDataByOnLine() {
      let data = this.watchData.data;
      this.watchData.data = [
        ...data.filter((e) => e.is_online == 1),
        ...data.filter((e) => e.is_online !== 1),
      ];
    },
    // 拉取初始化数据
    fetchData(id = this.user_info.team_id) {
      let _this = this;
      // console.log("fetch", id);
      // // 层级管理，获取全体数据暂不处理，以用户信息替换-1
      // if (id === -1) {
      //   id = this.user_info.team_id;
      // }
      this.watchData.data = [];
      // 获取团队聊天列表
      this.$post(Api.getLiveAdminList(), {
        team_id: id,
      })
        .then((res) => {
          if (res.code === 1) {
            // this.expandWatchData(res.data);
            _this.watchData.data = res.data.filter(item=> {
              return item.is_manager !== 1 
            });
            // if (id === -1) {
            //   res.data.forEach((ele) => {
            //     ele.user_list.forEach((e) => {
            //       _this.watchData.data.push(e);
            //     });
            //   });
            // } else {
            //   _this.watchData.data = res.data;
            // }
            this.expandWatchData(_this.watchData);
            this.updateOnLineCount(_this.watchData, false);
            // this.generateListMap(this.watchData.data);
            this.reRankDataByOnLine();
            this.connectSocket();
            // this.updateOnLineCount(this.watchData);
          } else {
            this.$Message.error(res.msg_customer);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notice.error({
            title: '一级管理员监控列表接口异常,返回无数据',
            desc: err.toString(),
          });
        })
        .finally(() => {
          this.spinShow = false;
        });
    },
    // 切换展示悬浮框的时候 需要通知map修改一些按钮的位置
    toggleFolder() {
      this.mapConfig.toolsMovingDistance = this.mapConfig.toolsMovingDistance
        ? 0
        : 345; // 右侧悬浮框移动
    },
    Map3DSwitch() {
      this.Shou3D = !this.Shou3D;
    },
    getRem(val) {
      return val + 'px';
    },

    // 快拼任务区域
    // 侧边栏
    toggleFolder2() {
      this.rapidMapConfig.toolsMovingDistance = this.rapidMapConfig
        .toolsMovingDistance
        ? 0
        : 340;
    },
    //显示快拼
    showRapidHandler(data) {
      this.rapidShou = true;
      this.rapidMapConfig.rapidStatus = true;
      this.rapidGetTheNodInfo = data;
    },
    //快拼返回直播
    RapidBackHandler() {
      this.rapidShou = false;
      this.rapidMapConfig.rapidStatus = false;
      this.rapidGetTheNodInfo = null;
      this.$_bus.$emit('clearMap');
      this.RapidVideo(false);
      this.rapidData = {
        task3DModel: '',
        taskName: '',
        id: '',
        activeMarker: -1,
        airLinePoints: 0,
        ariLineLength: 0, //航线长度
        flyingExpectTime: 0, //预计飞行时间
        speed: 8, //飞行速度 (m/s)
        height: 100, //飞行高度 (m)
        targetHeight: 0, //目标高度 (m)
        relativeHeight: 100, //相对高度 (m)
        select: 1, //任务完成动作
        pointHeight: 50,
        pointAngle: 0, //云台俯仰角度 (°)
        airArea: 0, //区域面积
        photoGraphTimes: 0, //照片数量
        courseOverlapRate: 80, //航线重叠率 (%)
        sideOverlapRate: 70, //旁向重叠率 (%)
        courseAngle: 0, //航线角度
        points: [],
        waypoint: [],
        defaultFov: 84,
        rapidPathArray: [],
      };
    },
    RapidSocketMSG(data) {
      if (this.rapidGetTheNodInfo && this.rapidGetTheNodInfo.uid == data.uid) {
        this.rapidGetTheNodInfo = data;
      }
    },
    //
    updateRapid(data) {
      this.rapidData = data;
    },
    // 地图发出来的信息
    RapidSendMsg() {},
    //快拼直播播放
    RapidVideo(data) {
      // console.log("?????????????", this.rapidGetTheNodInfo);
      if (data) {
        this.videoBoxShou = true;
        this.$post(Api.getLiveUrl(), {
          client: 'web',
          user_id: this.rapidGetTheNodInfo.uid,
        }).then((res) => {
          // console.log("快拼直播播放", res);
          if (res.data) {
            // if (this.player !== null) {
            //   this.player.destroy();
            // }
            // this.player = new TcPlayer('videoBox', {
            //   // "m3u8": res.data.m3u8_url,
            //   flv: res.data.flv_url,
            //   h5_flv: true,
            //   //   rtmp: res.data.default_url,
            //   live: true,
            //   autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
            //   width: '100%', //视频的显示宽度
            //   height: '100%', //视频的显示高度
            // });
            // setTimeout(() => {
            //   this.player.play();
            // }, 400);

            // ========
            ///////
            /**
             * flv.js
             * 离线的flv播放器
             * 不依赖flash
             * 目前作为通用flv播放器,取代tcplayer
             */
            if (this.player !== null) {
              this.player.pause();
              this.player.unload();
              this.player.detachMediaElement();
              this.player.destroy();
              this.player = null;
            }
            this.$nextTick(() => {
              if (flvjs.isSupported()) {
                this.player = flvjs.createPlayer({
                  type: 'flv',
                  url: res.data.flv_url,
                  isLive: true,
                });
                this.player.attachMediaElement(
                  document.getElementById('videoBox')
                );
                this.player.load();
                this.player.play();
              } else {
                this.$message.error('不支持flv格式');
                return;
              }
            });
            // ========

            // if (this.player !== null) {
            //   this.player.videoClear();
            //   this.player = null;
            // }
            // let videoObject = {
            //   container: '#videoBox', //容器的ID或className
            //   variable: 'player', //播放函数名称
            //   autoplay: true,
            //   live: true,
            //   video: res.data.default_url,
            //   loaded: 'loadedHandler', //当播放器加载后执行的函数
            // };
            // this.player = new ckplayer(videoObject);
          }
        });
      } else {
        this.videoBoxShou = false;
        if (this.player !== null) {
          this.player.pause();
          this.player.unload();
          this.player.detachMediaElement();
          //////
          this.player.destroy();
          this.player = null;
          /////
          //   this.player.videoClear();
        }
      }
    },
    changeTeamData(value, selectedData) {
      //选择的团队改变
      // console.log("选择的团队改变",selectedData);
      this.TeamChatData = JSON.parse(JSON.stringify(this.TeamChatDefault)); //深拷贝
      this.TeamChatData = Object.assign({}, this.TeamChatData, {
        isTeam: true,
        user_id: value,
        // name: (selectedData && selectedData.label) || this.user_info.team_name,
        // team_id:this.user_info.team_id,
        team_id: -1,
      });
    },
  },
  beforeDestroy() {
    if (this.player !== null) {
      this.player.pause();
      this.player.unload();
      this.player.detachMediaElement();
      //////
      this.player.destroy();
      this.player = null;
      /////
      //   this.player.videoClear();
    }
  },
};
</script>

<style lang="scss" scoped>
.watch {
  width: 100%;
  height: 100%;
  //人员列表
  .watchBox {
    width: 100%;
    height: 100%;
    position: relative;
    .MapSwitch {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background-color: #000;
      opacity: 0.7;
      cursor: pointer;
      position: absolute;
      top: 50px;
      transition: all 0.3s ease-in-out;
      z-index: 100;
      img {
        width: 80%;
        height: auto;
      }
    }
    #Map3D {
      opacity: 1;
    }
    .userInfoBox {
      position: absolute;
      z-index: 100;
      bottom: 30px;
      background-color: #fff;
      border-radius: 4px;
      width: 184px;
      height: 207px;
      border: 1px solid #707070;
      padding: 15px 17px;
      font-size: 14px;
      color: #000;
      div {
        display: flex;
        align-items: center;
      }
      img {
        margin-right: 13px;
        margin-left: 4px;
      }
      span {
        width: 116px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .name {
        height: 24px;
        font-size: 16px;
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 10px;
          margin-left: 0px;
        }
      }
      .unit {
        color: #707070;
        margin-left: 34px;
        margin-bottom: 12px;
      }
      .policeNum {
        margin: 9px 0;
      }
      .phone > img {
        margin-left: 7px;
        margin-right: 14px;
      }
      .btns {
        margin-top: 9px;
        span {
          display: inline-block;
          width: 67px;
          height: 28px;
          text-align: center;
          line-height: 28px;
          border: 1px solid #f56c6c;
          color: #f56c6c;
          border-radius: 4px;
          cursor: pointer;
        }
        span:last-child {
          margin-left: 12px;
          border: 1px solid #2196f3;
          background: #2196f3;
          color: #fff;
        }
      }
    }
  }
  //快拼
  .rapid {
    width: 100%;
    height: 100%;
    position: relative;
    .videoBox {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 400px;
      height: 250px;
      background-color: #b7b7b7;
    }
  }
}
</style>
